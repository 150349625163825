<template>
  <div>
    <v-btn
      width="100%"
      height="50"
      color="primary"
      class="my-3"
      :loading="loading"
      @click.prevent="$emit('submitForm', $event)"
    >
      {{ submitText }}
    </v-btn>
    <v-btn
      width="100%"
      height="50"
      color="grey lighten-1"
      class="my-3"
      @click="$auth.universalLogin({ connection: 'google-oauth2' })"
    >
      <div class="d-flex align-center justify-center">
        <span class="font-weight-regular mr-3">{{ google }}</span>
        <v-img src="@/data/static/GoogleLogo.png" max-width="20"></v-img>
      </div>
    </v-btn>
    <v-btn
      width="100%"
      height="50"
      color="grey lighten-1"
      class="my-3"
      @click="$auth.universalLogin({ connection: 'linkedin' })"
    >
      <div class="d-flex flex-align-center justify-center">
        <span class="font-weight-regular mr-3">
          {{ linkedin }}
        </span>
        <v-img src="@/data/static/LinkedInLogo.png" max-width="20"></v-img>
      </div>
    </v-btn>
  </div>
</template>

<script>
import { FORM_GOOGLE, FORM_LINKEDIN, GEN_LOG_IN, GEN_SIGN_UP } from '@/data/constants/messages';
import { capitalize } from 'lodash/string';

export default {
  name: 'Buttons',
  props: {
    login: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      google: FORM_GOOGLE,
      linkedin: FORM_LINKEDIN,
    };
  },
  computed: {
    submitText() {
      return this.login ? capitalize(GEN_LOG_IN) : capitalize(GEN_SIGN_UP);
    },
  },
};
</script>

<style scoped></style>
