<template>
  <section>
    <div class="input-row">
      <v-text-field
        :label="SUB_ADDRESS_ONE"
        v-model="value.address1"
        solo
        dense
        color="blue lighten-3"
        :rules="[rules.required]"
      ></v-text-field>
      <v-text-field
        v-model="value.address2"
        :label="SUB_ADDRESS_TWO"
        solo
        dense
        color="blue lighten-3"
      ></v-text-field>
    </div>
    <div class="input-row">
      <v-text-field
        :label="SUB_CITY"
        v-model="value.city"
        solo
        dense
        color="blue lighten-3"
        :rules="[rules.required]"
      ></v-text-field>
      <v-autocomplete
        :label="SUB_STATE"
        :items="states"
        item-text="label"
        item-value="message"
        :disabled="value.country !== 'US'"
        v-model="value.state"
        solo
        dense
        color="blue lighten-3"
        :rules="value.country === 'US' && [rules.required]"
      ></v-autocomplete>
    </div>
    <div class="input-row">
      <v-autocomplete
        v-model="value.country"
        :label="SUB_COUNTRY"
        :items="countries"
        item-text="country_name"
        item-value="country_code"
        solo
        dense
        color="blue lighten-3"
        :rules="[rules.required]"
      >
      </v-autocomplete>
      <v-text-field
        :label="SUB_ZIP"
        v-model="value.zipCode"
        solo
        dense
        color="blue lighten-3"
        :rules="[rules.required]"
      ></v-text-field>
    </div>
  </section>
</template>

<script>
import { states, countries } from '@/data/constants';
import {
  SUB_ADDRESS_ONE,
  SUB_ADDRESS_TWO,
  SUB_CITY,
  SUB_STATE,
  SUB_COUNTRY,
  SUB_ZIP,
  SUB_BILLING,
} from '@/data/constants/messages';

export default {
  props: {
    value: Object,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required',
      },
      states,
      countries,
      SUB_BILLING,
      SUB_COUNTRY,
      SUB_ADDRESS_TWO,
      SUB_ADDRESS_ONE,
      SUB_CITY,
      SUB_STATE,
      SUB_ZIP,
    };
  },
};
</script>

<style lang="scss" scoped></style>
