/**
 * unpackers module
 */

import { formatDescription } from './unpackerHelpers/descriptionFormatters';
import InboundDiagram from '@/data/Diagram';

/**
 * Unpacks the document and formats it for the description editor
 *
 * @param document - Document string from the server
 * @return {String} - Formatted HTML string to use in the description editor
 */
export function unpackDescription(document) {
  const paragraphs = typeof document === 'string' ? document.split('\n') : document;

  // Iterate over paragraphs, calling formatDescription on each iteration
  return paragraphs.reduce(formatDescription, '');
}

export function unpackDiagrams(diagrams) {
  return diagrams.map((d) => {
    const diagram = new InboundDiagram(d.label, d.id, d.illustration, d.overflow);
    diagram.createBlocks(d.blocks);
    diagram.createLinks(d.lines);
    return diagram;
  });
}

export function unpackClaims(doc) {
  return doc.reduce((claims, span) => {
    claims += `<p>${span}</p>`;
    return claims;
  }, '');
}
