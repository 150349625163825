<template>
  <SignupLayout>
    <v-container
      tag="section"
      class="d-flex flex-column justify-center main-container align-center"
      id="login"
    >
      <!-- Dev environment warning. Does not appear in production-->
      <EnvironmentWarning :link="officialAppLink" v-if="showWarning" />

      <h1 class="text-h4" v-text="title" />

      <!-- Main form -->
      <v-form ref="form" class="mt-12 d-flex flex-column justify-center align-center">
        <slot />
        <span class="caption grey--text mb-4">
          {{ privacyText }}{{ ' '
          }}<a class="grey--text" href="https://patentpal.com/privacy">{{ privacyLink }}</a>
        </span>
        <Buttons :login="login" @submitForm="submitForm" :loading="loading" />
      </v-form>
      <!-- End main form-->

      <!-- Link to signup or login page-->
      <p class="caption mt-4" v-if="login">
        {{ newUser }}{{ ' '
        }}<router-link to="/signup" class="primary--text">{{ signupHere }}</router-link>
      </p>
      <p v-else class="caption mt-4">
        {{ returnUser }}{{ ' '
        }}<router-link to="/login" class="primary--text">{{ loginHere }}</router-link>
      </p>
      <!-- End link to signup or login page-->

      <Message
        v-model="snackbar"
        :message="errorMessage"
        color="error"
        @close="snackbar = false"
        absolute
      />
    </v-container>
  </SignupLayout>
</template>

<script>
import SignupLayout from './AccountLayout';
import { EnvironmentWarning, Buttons } from '@/components/signup';
import {
  PRIVACY_TEXT,
  PRIVACY_LINK,
  FORM_NEW_USER,
  GEN_SIGN_UP,
  FORM_RETURN_USER,
  GEN_LOG_IN,
} from '@/data/constants/messages';
import { capitalize } from 'lodash/string';
import { Message } from '@/components/global';

export default {
  name: 'AuthenticationLayout',
  components: {
    SignupLayout,
    EnvironmentWarning,
    Buttons,
    Message,
  },
  props: {
    login: {
      type: Boolean,
      default: true,
      required: false,
    },
    title: {
      type: String,
      default: 'Log in',
      required: false,
    },
    loading: Boolean,
    error: Boolean,
    errorMessage: String,
  },
  data() {
    return {
      privacyText: PRIVACY_TEXT,
      privacyLink: PRIVACY_LINK,
      signupHere: `${capitalize(GEN_SIGN_UP)} here`,
      newUser: FORM_NEW_USER,
      returnUser: FORM_RETURN_USER,
      loginHere: `${capitalize(GEN_LOG_IN)} here`,
    };
  },

  computed: {
    /**
     * @returns {string}
     */
    officialAppLink() {
      return this.login
        ? 'https://draft.patentpal.com/login'
        : 'https://draft.patentpal.com/signup';
    },

    /**
     * @returns {boolean}
     */
    showWarning() {
      return process.env.VUE_APP_ENV !== 'production';
    },

    snackbar: {
      /**
       * @returns {boolean}
       */
      get() {
        return this.error;
      },

      set() {
        this.$emit('closeError');
      },
    },
  },

  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$emit('submit');
      }
    },
  },
};
</script>

<style>
.v-input--selection-controls__input {
  margin-right: 0 !important;
}
</style>
