import { default as RE } from './regexConstructors';
import { UploadKeys as K } from '@/data/constants';
import { DEFAULT_FONT_FAMILY } from '@/data/constants/DiagramConstants';
import lodash from 'lodash';

/**
 * Find first item in array that matches provided matcher.
 *
 * Array.find only returns first message, array.findIndex only returns index. Sometimes
 * we need so we can use this function to avoid unnecessary looping.
 *
 * @param {Array} arr - Array to iterate
 * @param {Function} matcher - Callback function to match values
 * @param  {...any} args - Infinite optional arguments
 * @returns {Object} - Object containing message and it's index
 */
export function findWithIndex(arr, matcher, ...args) {
  for (let i = 0, l = arr.length; i < l; i++) {
    const item = arr[i];
    const match = matcher(item, ...args);
    if (match) return { item, index: i };
  }
}

export function fileDate() {
  const date = new Date();
  return `${date.getFullYear().toString().substr(2)}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function titleCase(str) {
  const temp = str.toLowerCase();
  return lodash.startCase(temp);
}

/**
 * Either get current range or create a new one
 * @param {Selection} [sel] - Optional selection object
 * @returns {Range} - a range object
 */
export function getRange(sel = null) {
  // If no selection, get the current selection
  const selection = sel ? sel : window.getSelection();

  // If selection has a range return the range, otherwise create new range
  return selection.rangeCount > 0 ? selection.getRangeAt(0) : document.createRange();
}

export function clickElement(selection) {
  document.querySelector(selection).click();
}

/**
 * Returns start and end values of text within a node
 * @param {Node} node - DOM Node
 * @param {String} text - String to find start and end values of
 * @returns {{start: Number, end: Number}}
 */
export function getStartAndEnd(node, text) {
  const string = typeof node === 'string' ? node : node.textContent;
  const start = string.indexOf(text);
  const end = start + text.length;
  return { start, end };
}

export function createElement(element) {
  return document.createElement(element);
}

export function getElementById(id) {
  return document.getElementById(id);
}

export function selectFile(cb, fileType = 'image/*') {
  const $temp = getElementById(K.TEMP_INPUT);
  $temp?.remove();

  const $input = createElement('input');
  $input.id = K.TEMP_INPUT;
  $input.type = 'file';
  $input.accept = fileType;
  $input.addEventListener('change', cb);
  $input.click();
}

export function getImageData(dataUrl) {
  return new Promise((res) => {
    const image = new Image();

    image.onload = () => {
      res(image);
    };

    image.src = dataUrl;
  });
}

export function readDataUrl(file, cb) {
  const reader = new FileReader();
  reader.onloadend = () => {
    cb(reader.result);
  };

  reader.readAsDataURL(file);
}

// START REGION STRING HELPERS

/**
 * Reverse order of a string
 * @param {String} str - String to reverse
 * @returns {String} - reversed string
 */
export function reverseString(str) {
  return str.split('').reverse().join('');
}

/**
 * Find last index of a message in a string
 * @param val - Value to find
 * @param str - String to search
 * @returns {number}
 */
export function getLastIndex(val, str) {
  if (val instanceof RegExp) {
    const lastInstanceOfVal = RE.lastInstanceOf(val);
    const match = str.match(lastInstanceOfVal);
    return calcEndIndex(match);
  }

  return str.lastIndexOf(val);
}

export function getFirstIndex(val, str) {
  return val instanceof RegExp ? str.search(val) : str.indexOf(val);
}

/**
 * Use lodash.truncate() to truncate a string at the provided length
 *
 * @param {string} text - String to truncate
 * @param {number} length - Length to truncate string at
 * @returns {string} - Truncate string
 */
export function truncate(text, length) {
  return lodash.truncate(text, { length });
}

/**
 * Calculate the maximum characters before wrapping in a container.
 *
 * Note: This is hard to do because character widths can vary from character to character in
 * non-mono-spaced fonts. As a result this is more of an estimation and not 100% accurate.
 *
 * This tends to be most accurate when calculating character width from a string we intend to insert into the container
 * @param text - Text to calculate the character width from.
 * @param containerWidth - Width of the container to calculate maximum characters for.
 * @param fontSize - Font size of the text
 * @returns {number} - Estimated maximum characters
 */
export function getMaxChars(text, containerWidth, fontSize) {
  const textWidth = getTextWidth(text, fontSize);
  const charWidth = Math.round(textWidth / text.length);
  return Math.floor(containerWidth / charWidth) - 2;
}

/**
 * Check if the provided text will wrap inside a container
 * @param text - Text to calculate the width of
 * @param containerWidth - Width of the container
 * @param fontSize - Font size of the text
 * @returns {boolean} - True if text will wrap, false if it will not.
 */
export function checkWillWrap(text, containerWidth, fontSize) {
  const textWidth = getTextWidth(text, fontSize);
  return textWidth > containerWidth;
}

/**
 * Calculate the width of a string at the provided text size
 * @param text - String to measure
 * @param fontSize - Font size of the string
 * @returns {number} - Width of the string
 */
export function getTextWidth(text, fontSize) {
  // Create the div element to insert text into
  const div = document.createElement('div');

  // Set element styles
  div.textContent = text;
  div.style.width = 'fit-content';
  div.style.fontSize = `${fontSize}px`;
  div.style.fontFamily = DEFAULT_FONT_FAMILY;

  // Attach to the DOM
  document.body.appendChild(div);

  // Get width of div then remove it from DOM
  const width = div.clientWidth;
  div.remove();
  return width;
}

function calcEndIndex(match) {
  return match ? match.index + match[0].length : -1;
}

export function formatErrorMessage(message, errorId) {
  // Ensuring the end of the message has a period
  message = message.trim().replace(/\.?$/, '.');
  errorId = errorId ? errorId.trim().replace(/\.?$/, '.') : errorId;

  return errorId ? `${message} Error ID: ${errorId}` : `${message}`;
}

// END REGION STRING HELPERS

// START REGION REGEX HELPERS
