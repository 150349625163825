import { Outline } from '@/data/Outline';
import InboundDiagram from '@/data/Diagram';
import { TierLimits as tl } from '@/data/constants';
import { v4 as uuid } from 'uuid';
import { DraftKeys as k } from '@/data/constants/vuexKeys';
import * as p from '@/data/constants/Phrases';

const state = {
  [k.CLAIMS]: '',
  [k.OUTLINES]: [new Outline({ tags: [], concepts: [], points: [{ text: '', children: [] }] })],
  [k.DIAGRAMS]: [new InboundDiagram(1, uuid())],
  [k.DESCRIPTION]: '',
  [k.STEP]: 1,
  [k.FURTHEST_STEP]: 1,
  [k.CLAIMS_CHANGED]: false,
  [k.OUTLINE_CHANGED]: false,
  [k.LOADING]: false,
  [k.OUTLINE_UP_TO_DATE]: false,
  [k.GET_DIAGRAMS_FROM_CLAIMS]: false,
  [k.TUTORIAL_OPT_OUT]: false,
  [k.PROFILE_OVERLAY]: false,
  [k.SAVED_OUTLINES]: null,
  [k.SAVED_CLAIMS]: null,
  [k.OUTLINE_FROM_CLAIMS]: false,
  [k.FOCUSED_TAG]: false,
  [k.DIAGRAMS_FROM_OUTLINE]: false,
  [k.DESCRIPTION_EDITOR_REF]: null,
  [k.CLAIMS_EDITOR_REF]: null,
  [k.DIAGRAM_EDITOR_REF]: null,
  [k.INSERTING]: false,
  [k.HANDLED_PROMPT]: false,
  [k.SHOW_TOOLTIP]: false,
  [k.HOVERING_TOOLTIP]: false,
  [k.SHOW_PHRASE]: true,
  [k.LOGOUT_PROMPT]: false,
  [k.SEQUENTIAL]: true,
  [k.VIEWING_TUTORIAL]: false,
  showX: false,
  draft: false,
  clicked: false,
  tempDiagrams: [],
  currentDiagram: 0,
  next: true,
  subscribePrompt: false,
  subscribePromptMessage: tl.DEFAULT_TEXT,
  rootScroll: null,
  activeProfile: {
    name: 'Default',
    editing: false,
    id: uuid(),
    truncateText: true,
    verbose_steps: true,
    phrases: {
      summary_intro: p.summary_intro,
      family_intro: p.family_intro,
      concept_intro: p.concept_intro,
      context_intro: p.context_intro,
      including: p.including,
      may_include: p.may_include,
      may_also_include: p.may_also_include,
    },
  },
};

export default state;
