<!--
  This is the main header.
-->

<template>
  <v-app-bar id="header-component" dark app dense>
    <MainMenu />
    <v-fade-transition>
      <v-tooltip
        bottom
        transition="none"
        nudge-top="10"
        content-class="patentpal-tooltip top-corners"
        v-if="showGenerate"
      >
        <template #activator="{ on, attrs }">
          <v-btn @click="$emit('showConfigMenu')" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-file-document-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>Customize</span>
      </v-tooltip>
    </v-fade-transition>
    <v-spacer />
    <v-progress-circular indeterminate v-if="loading" />
    <UserMenu v-if="!loading" />
  </v-app-bar>
</template>

<script>
import { MainMenu, UserMenu } from './subComponents';
import { GEN_LOG_IN, GEN_SIGN_UP } from '@/data/constants/messages';
import { capitalize } from 'lodash/string';
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  components: {
    MainMenu,
    UserMenu,
  },

  data() {
    return {
      signupText: capitalize(GEN_SIGN_UP),
      loginText: capitalize(GEN_LOG_IN),
      show: false,
    };
  },

  computed: {
    ...mapGetters('draft', ['showGenerate']),
    /**
     * @returns {Boolean}
     */
    authenticated() {
      return !!this.$auth.user;
    },

    /**
     *
     * @returns {Boolean}
     */
    loading() {
      return this.$auth.loading;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

$img-size: 30px;

#header-component {
  background-color: $dark-background;
}

.spacer {
  border-right: 1px solid $strong-gray;
  margin: 0;
}
</style>
