<template>
  <div class="panel">
    <span class="panel-label">
      {{ label }}
    </span>
    <Scrollable
      class-name="panel-content"
      auto-hide="move"
      ref="contentContainer"
      @overflowAmountChanged="$emit('overflowAmountChanged', $event)"
      overflow-x="hidden"
    >
      <slot />
    </Scrollable>
    <v-fade-transition>
      <v-btn
        :class="step === 2 ? 'arrowButton' : 'arrowButton step2'"
        fab
        :color="arrowColor"
        @click="$emit('arrowClick')"
        small
        :disabled="!validClaims"
        v-if="arrowButton"
        elevation="0"
      >
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </v-fade-transition>
  </div>
</template>

<script>
import Scrollable from '../global/Scrollable';
import { messages as M } from '@/data/constants';
import { DraftKeys as dk } from '@/data/constants/vuexKeys';
import { mapGetters, mapMutations, mapState } from 'vuex';
import {
  GENERATE_BUTTON_MESSAGE,
  REGENERATE_BUTTON_MESSAGE,
} from '@/data/constants/TooltipConstants';

export default {
  name: 'Panel',
  components: {
    Scrollable,
  },
  props: {
    label: String,
    arrowButton: Boolean,
  },
  data() {
    return {
      GENERATE_BUTTON_MESSAGE,
    };
  },

  mounted() {
    if (this.label === M.GEN_DESCRIPTION) {
      this.setDraftState({
        key: 'descriptionEditorRef',
        val: this.$refs.contentContainer.$refs.osRef.osInstance(),
      });
    }

    if (this.label === `${M.GEN_CLAIM}s`) {
      this.setDraftState({
        key: 'claimsEditorRef',
        val: this.$refs.contentContainer.$refs.osRef.osInstance(),
      });
    }

    if (this.label === `${M.GEN_DIAGRAM}s`) {
      this.setDraftState({
        key: 'diagramEditorRef',
        val: this.$refs.contentContainer.$refs.osRef.osInstance(),
      });
    }
  },

  computed: {
    ...mapState('draft', { loading: dk.LOADING, inserting: dk.INSERTING, step: dk.STEP }),
    ...mapGetters('draft', ['outlineChanged', 'claimsChanged', 'validClaims', 'initialOutline']),
    ...mapState('tooltip', ['showTooltip']),
    arrowColor() {
      return (this.outlineChanged || (this.claimsChanged && this.validClaims)) &&
        !this.loading &&
        !this.inserting
        ? 'primary'
        : 'lightGray';
    },

    tooltipMessage() {
      return this.step === 1 ? GENERATE_BUTTON_MESSAGE : REGENERATE_BUTTON_MESSAGE;
    },
  },

  methods: {
    ...mapMutations({
      setDraftState: 'draft/setDraftState',
      setTooltipState: 'tooltip/setTooltipState',
    }),
  },
};
</script>

<style lang="scss" scoped>
$panel-height: 100%;

.panel-content {
  width: 100%;
  height: calc(100% - 30px) !important;
  color: $panel-text-color !important;
}

.yeet {
  height: 100%;
  width: 100%;
}

.panel {
  .arrowButton {
    position: absolute;
    z-index: 100;
    background-color: $light-gray;
    top: calc(50%);
    right: -30px;
    transition: background-color 0.3s;

    &.step2 {
      right: -70px;
    }

    &.v-btn.v-btn--disabled {
      background-color: $light-gray !important;
    }

    &.changed {
      background-color: $primary;
      color: white !important;
    }
  }
}

.panel-label {
  display: block;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  padding-left: 8px;
  width: fit-content;
}
</style>
