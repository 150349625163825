<template>
  <div
    @drag.prevent
    @dragenter.prevent="handleDragEnter"
    @dragover.prevent
    @drop.prevent="handleDrop"
    id="AppLayout"
  >
    <HeaderComponent @showConfigMenu="showConfigMenu = true" />
    <v-fade-transition>
      <ProfileOverlay v-if="showProfile" />
    </v-fade-transition>
    <Alerts />
    <UploadOverlay />
    <UserConfigsMenu :show-config-menu="showConfigMenu" @closeMenu="showConfigMenu = false" />
    <main class="main__default-view" v-if="!hideContent">
      <slot />
    </main>
    <LogoutPrompt />
    <SubscribePrompt />
    <v-overlay :value="mobileAlert" opacity=".75">
      <PromptCard
        video
        text="Oops, we do not yet support mobile devices. Please use a laptop or desktop computer instead."
        title="Mobile device"
        cancel-hidden
        okay-text="Log out"
        @okay="logout"
      />
    </v-overlay>
    <v-overlay :value="browserAlert" opacity=".75">
      <PromptCard
        :colorOkay="false"
        :text="`Looks like you may be using ${browser}. For the best experience, please consider using <a href='https://www.google.com/chrome/' target='_blank' class='blue--text'>Chrome</a>.`"
        cancel-text="Don't show again"
        @okay="hideBrowserAlert"
        @cancel="dontShowAgain"
      />
    </v-overlay>
    <tutorial-component v-if="!hideContent" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { UploadOverlay } from '@/components/upload';
import { TutorialComponent } from '@/components/tutorial';
import { ProfileOverlay, Alerts, PromptCard, SubscribePrompt } from '@/components/global';
import { COMPANY_LEGAL_NAME, COMPANY_SUPPORT_EMAIL } from '@/data/constants/messages';
import { UploadKeys as UK, ErrorKeys as EK } from '@/data/constants';
import { DraftKeys as dk } from '@/data/constants/vuexKeys';
import LogoutPrompt from '@/components/global/LogoutPrompt';
import { ACTION_DROP_UPLOAD } from '@/data/constants/AnalyticEventKeys';
import UserConfigsMenu from '@/components/global/UserConfigsMenu';
import HeaderComponent from '@/components/header';

export default {
  name: 'AppLayout',
  components: {
    UserConfigsMenu,
    LogoutPrompt,
    PromptCard,
    ProfileOverlay,
    TutorialComponent,
    UploadOverlay,
    Alerts,
    HeaderComponent,
    SubscribePrompt,
  },
  data() {
    return {
      show: false,
      dialog: false,
      COMPANY_LEGAL_NAME,
      COMPANY_SUPPORT_EMAIL,
      mobileAlert: false,
      hideContent: false,
      showConfigMenu: false,
      browserAlert: false,
      browser: '',
    };
  },
  async mounted() {
    window.addEventListener('unload', () => {
      localStorage.removeItem('graph-data');
    });

    const isOnMobile = this.checkForMobile(navigator.userAgent || navigator.vendor || window.opera);

    if (isOnMobile) {
      this.mobileAlert = true;
      this.hideContent = true;
    }

    const usingChrome = this.checkForChrome();

    if (!usingChrome && !isOnMobile) {
      const doNotShow = !!localStorage.getItem('doNotShowBrowserAlert');
      if (!doNotShow) {
        this.browserAlert = true;
        this.hideContent = true;
      }
    }
  },

  computed: {
    ...mapState({
      showProfile: (state) => state.draft[dk.PROFILE_OVERLAY],
    }),
  },

  methods: {
    ...mapMutations({
      setUploadState: 'upload/setUploadState',
      saveFile: 'upload/saveFile',
      setError: 'error/setErrorState',
      setState: 'draft/setDraftState',
      setLoading: 'draft/setLoading',
    }),

    dontShowAgain() {
      const local = window.localStorage;
      local.setItem('doNotShowBrowserAlert', 'true');
      this.browserAlert = false;
      this.hideContent = false;
    },

    hideBrowserAlert() {
      this.browserAlert = false;
      this.hideContent = false;
    },

    logout() {
      this.setState({ key: dk.SHOW_PHRASE, val: false });
      this.mobileAlert = false;
      this.setLoading(true);

      try {
        this.$auth.logout();
      } catch (err) {
        console.error(err);
        this.setLoading(false);
        this.mobileAlert = true;
      }
    },

    checkForMobile(a) {
      return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          a.substr(0, 4)
        )
      );
    },

    checkForChrome() {
      const userAgent = navigator.userAgent;

      let usingChrome = userAgent.indexOf('Chrome') > -1;

      if (!usingChrome) {
        if (userAgent.indexOf('Safari') > -1) this.browser = 'Safari';
      }

      if (userAgent.indexOf('Firefox') > -1) {
        this.browser = 'Firefox';
        usingChrome = false;
      }

      if (userAgent.indexOf('Edg') > -1) {
        this.browser = 'Microsoft Edge';
        usingChrome = false;
      }

      if (navigator.brave !== undefined && navigator.brave.isBrave()) {
        this.browser = 'Brave';
        usingChrome = false;
      }

      if (navigator.userAgent.match(/Opera|OPR\//)) {
        this.browser = 'Opera';
        usingChrome = false;
      }

      return usingChrome;
    },

    handleDragEnter(e) {
      const draggingFile = e.dataTransfer.types[0] === 'Files';
      if (draggingFile) {
        this.setUploadState({ key: UK.DROP, val: true });
      }
    },
    handleDrop(e) {
      this.show = false;
      const file = e.dataTransfer.files[0];

      if (file) {
        if (
          file?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
          this.saveFile({ e, action: ACTION_DROP_UPLOAD });
        } else {
          const startsWithVowel = /^[aieouAIEOU].*/;
          this.setUploadState({ key: UK.DROP, val: false });
          this.setError({
            key: EK.ERROR,
            val: true,
            message: `You can only use Word files, the file you provided was ${
              startsWithVowel.test(file.type) ? 'an' : 'a'
            } ${file.type.replace(/\/[a-z]*/, '')} file.`,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '../scss/variables';

.configMenu {
  .v-snack {
    top: 20px !important;
    // transition: none !important;
  }

  .v-snack__wrapper {
    transition: none !important;
  }

  .v-snack__action {
    margin: 0 !important;
  }
}

.figLabel {
  display: inline-block;
  box-sizing: border-box;
  line-height: 22px;
  border-bottom: 3px solid transparent;
  &:hover {
    border-bottom: 3px solid $focus;
  }
}

.mxCellEditor.mxPlainTextEditor {
  border: 3px solid;
  border-color: transparent;
  border-bottom-color: $focus;
  display: inline-block;
  border: 3px solid $focus;
  padding-top: 0;
}

.v-overlay__content {
  max-width: 90%;
}

#dropoverlay {
  * {
    pointer-events: none !important;
  }
}

.tag {
  box-sizing: border-box;
  border-bottom: 3px solid #ffe699;
  transition: background 0.3s, border 0.3s, color 0.3s;
  &:hover {
    cursor: pointer;
    color: black;
    &.lead {
      border-bottom: 3px solid #ffb800;
    }
  }

  &.head {
    font-weight: bold;
  }

  &.hovered {
    background: #fff4d6;
    border-bottom-width: 3px;
    color: black;
    &.lead {
      border-bottom: 3px solid #ffb800;
    }
  }

  &:focus {
    cursor: text !important;
    background: #ebf4ff;
    border-bottom: 3px solid #86c0fd !important;
    outline: none;
    color: black;
  }

  &.focused {
    cursor: text !important;
    background: #ebf4ff;
    border-bottom: 3px solid #86c0fd;
    color: black;
  }
}

.link {
  box-sizing: border-box;
  border-bottom: 3px solid #bcdda6;
  transition: background 0.3s, border 0.3s;
  &:hover {
    cursor: pointer;
    background: #d6ffdc;
    border-bottom: 3px solid #89b76b;
  }

  &:focus {
    background: #ebf4ff;
    border-bottom: 3px solid #86c0fd;
    outline: none;
  }
}

.v-step {
  border-radius: 4px !important;
  background-color: #fff !important;
  color: $dark-gray !important;
  text-align: left !important;
  padding: 0 !important;
  .v-step__arrow {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
  }
}

.v-tooltip__content {
  pointer-events: auto !important;
  transition: opacity 0.5s;

  p {
    color: $dark-gray !important;
    padding: 0 35px;
    margin-bottom: 30px !important;
  }
  &.menuable__content__active {
    &.custom-tooltip {
      transition: opacity 0.5s;
      opacity: 1 !important;
    }
  }

  &.custom-tooltip {
    div {
      display: flex;
      flex-direction: column;
      align-content: end;
      height: 100%;
      width: 100%;
    }
    background-color: $white !important;
    border-radius: 4px !important;
    padding: 0;
    pointer-events: auto !important;
    overflow: inherit !important; // that's important to make the pseudo-element visible outside the main container
    z-index: 20;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
  }

  &.top-arrow {
    &::before {
      border-right: solid 8px transparent;
      border-left: solid 8px transparent;
      border-bottom: solid 8px $white;
      transform: translateX(-50%);
      position: absolute;
      z-index: -21;
      content: '';
      bottom: 100%;
      left: 50%;
      height: 0;
      width: 0;
    }
  }

  &.left-arrow {
    &::before {
      border-top: solid 8px transparent;
      border-bottom: solid 8px transparent;
      border-right: solid 8px $white;
      transform: translateY(-50%);
      position: absolute;
      z-index: -21;
      content: '';
      top: 50%;
      right: 100%;
      height: 0;
      width: 0;
    }
  }

  &.right-arrow {
    &::before {
      border-top: solid 8px transparent;
      border-bottom: solid 8px transparent;
      border-left: solid 8px $white;
      transform: translateY(-50%);
      position: absolute;
      z-index: -21;
      content: '';
      top: 50%;
      left: 100%;
      height: 0;
      width: 0;
    }
  }

  &.bottom-arrow {
    &::before {
      border-right: solid 8px transparent;
      border-left: solid 8px transparent;
      border-top: solid 8px $white;
      transform: translateX(-50%);
      position: absolute;
      z-index: -21;
      content: '';
      top: 100%;
      left: 50%;
      height: 0;
      width: 0;
    }
  }

  &.left-edge {
    &::before {
      left: 6% !important;
    }
  }

  &.right-edge {
    &::before {
      left: 80% !important;
    }
  }
}

.main__default-view {
  min-height: 100vh;
}

.ql-tooltip {
  position: absolute;
  transition: visibility 0.2s, opacity 0.2s;
  z-index: 3;
}

.ql-tooltip.ql-hidden {
  display: initial !important;
  top: 0;
  left: 0;
  opacity: 0;
  height: 0;
  visibility: hidden;
}

.v-window--item {
  width: fit-content !important;
}

.v-stepper__step--inactive {
  .v-stepper__step__step {
    background-color: #bdbdbd !important;
  }
}

.configMenu {
  .v-text-field--outlined {
    fieldset {
      border: none !important;
      transition-property: border-width !important;
    }

    &.v-input--is-focused {
      fieldset {
        border: none !important;
      }
    }
    .v-label {
      top: 6px !important;
    }

    .v-label--active {
      transform: translateY(-14px) scale(0.75) !important;
    }
  }
  .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control,
  > .v-input__slot,
  .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot,
  .v-text-field--filled.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
  .v-text-field--full-width.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outlined.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot {
    min-height: 30px !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: white;
    transition: none !important;
    &:hover {
      background: $highlight !important;
    }
  }

  .theme--light.v-text-field--filled.v-input--is-focused > .v-input__control > .v-input__slot {
    background: $highlight !important;
  }
}

#quill-claims,
#quill-description,
#quill-preview {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100% !important;
  border: none !important;
  .ql-editor {
    padding: 20px !important;
    flex-grow: 1;
    min-height: 100%;
    width: 100%;
    transition: height 0.3s, width 0.3s;
    overflow-y: overlay;
    p,
    li,
    h1,
    h2,
    h3 {
      margin-bottom: 16px;
      transition: opacity 50ms;
    }
    ul,
    ol {
      padding-left: 0.5em;
    }

    h1,
    h2,
    h3 {
      font-size: inherit;
      margin-top: 32px;
      &:first-child {
        margin-top: 0;
      }
    }

    h1,
    h2 {
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
    }

    h3 {
      font-style: italic;
      font-weight: normal;
    }

    &::before {
      font-style: normal;
      font-weight: normal;
      color: $soft-gray;
      padding-left: 5px !important;
      background-color: white;
    }

    &:focus {
      &::before {
        display: none;
      }
    }

    .hidden {
      opacity: 0;
    }
  }
}

#quill-preview {
  .ql-editor {
    padding: 15px 20px 0 20px !important;
    position: relative;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.mxTooltip {
  display: none !important;
}

.theme--light.v-application {
  background-color: $light-gray !important;
}

.theme--dark.v-list,
.v-menu__content {
  background-color: $dark-background !important;
}

.v-stepper__step__step {
  width: 40px !important;
  height: 40px !important;
  font-size: 18px !important;
}

.v-stepper__label {
  font-size: 16px !important;
}

.v-stepper__step {
  cursor: default !important;
}

.v-stepper__step--complete {
  .stepper__step__step.primary {
    background-color: $soft-gray !important;
  }
}
.v-window {
  overflow: visible !important;
}

.v-application {
  //font-family: 'Open Sans', sans-serif !important;
  ul {
    padding-left: 0 !important;
  }
}

.v-btn {
  font-weight: 600 !important;
}

.v-tabs-bar {
  background-color: $light-gray !important;
}
.v-tab {
  text-transform: initial !important;
  height: 30px !important;
}

.v-tabs {
  height: 30px !important;
}

.v-tabs-slider {
  display: none !important;
}

.v-tab--active {
  color: black !important;
}

.v-btn {
  text-transform: none !important;
  font-weight: normal !important;
  font-size: 17px !important;
  letter-spacing: normal !important;
  &.suggested {
    font-weight: bold !important;
  }
}

body div.mxPopupMenu {
  -webkit-box-shadow: 3px 3px 6px #c0c0c0;
  -moz-box-shadow: 3px 3px 6px #c0c0c0;
  box-shadow: 3px 3px 6px #c0c0c0;
  background: white;
  position: absolute !important;
  border: 3px solid #e7e7e7;
  padding: 3px !important;
  display: block;
}

body table.mxPopupMenu {
  border-collapse: collapse;
  margin: 0;
}

body tr.mxPopupMenuItem {
  color: black;
  cursor: default;
}

body td.mxPopupMenuItem {
  padding: 3px 30px 3px 10px;
  font-family: Arial, sans-serif;
  font-size: 10pt;
  &.mxDisabled {
    color: $soft-text-color;
  }
}

body td.mxPopupMenuIcon {
  background-color: white;
  padding: 0;
}

body tr.mxPopupMenuItemHover {
  background-color: #eeeeee;
  color: black;
}

div.mxWindow {
  display: none !important;
}

table.mxPopupMenu hr {
  border-top: solid 1px #cccccc;
}

table.mxPopupMenu tr {
  font-size: 4pt;
}

div.mxRubberband {
  position: absolute;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #0000ff;
  background: #0cddff;
}

.prevent.v-overlay__content,
.prevent * {
  pointer-events: none !important;
}

.customStyle {
  .v-input__slot:before {
    border: none !important;
  }
  .v-input__slot:after {
    border: none !important;
  }
}

.v-input--checkbox {
  label.v-label {
    color: rgba(0, 0, 0, 0.87);
    margin-left: 10px;
  }
}
</style>
