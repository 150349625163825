import { GEN_DIAGRAM, GEN_DESCRIPTION } from '@/data/constants/messages';
const figs = `${GEN_DIAGRAM.toLowerCase()}s`;
const spec = `${GEN_DESCRIPTION.toLowerCase()}`;

export const TOOLTIP_DURATION_MS = 2500;
export const MENU_TOOLTIP_ID = 'mainMenuTooltip';

export const GENERATE_BUTTON_MESSAGE =
  "Once you're happy with your claims, click here to generate a draft.";
export const DOWNLOAD_BUTTON_MESSAGE =
  'Click here to download the outputs as Word and Visio files.';
export const MENU_BUTTON_MESSAGE =
  'If you need to see the tutorial again, you can find it under the main menu.';

export const REGENERATE_BUTTON_MESSAGE = `Click here to generate a new set of ${figs} and ${spec} from the revised claims.`;
