/**
 * Block data formatted for Diagram Editor
 */
import { DiagramConstants as dc } from '@/data/constants';
import { getTextWidth } from '@/support/utilities';

export default class InboundBlock {
  /**
   * Creates a new Block
   * @param {object} block Object to be converted to block
   * @param {String} block.id - A valid UUID
   * @param {String} block.text - A string of text
   * @param {String} block.label - A string of text
   * @param {String} block.type - Must be one of CONTEXT, CONCEPT, or null
   * @param {Number} block.x - Must be a number greater than -1 and less than 51
   * @param {Number} block.y - Must be a number greater than -1 and less than 71
   * @param {Number} block.width - Must be a number greater than -1 and less than 51
   * @param {Number} block.height - Must be a number greater than -1 and less than 71
   * @param {String} block.parent - Must be a valid UUID
   * @param {Object[]} block.children
   * @param {Object[]} block.links
   * @param {Object[]} block.concepts
   */
  constructor(block) {
    this.id = block.id;
    this.text = block.text;
    this.label = block.label;
    this.type = block.type;
    this.x = block.x;
    this.y = block.y;
    this.width = block.width;
    this.height = block.height;
    this.parent = block.parent;
    this.children = block.children;
    this.links = block.lines;
    this.concepts = block.concept;
    this.shape = block.shape;
    this.direction = 0;

    // validateBlock(this);
  }

  /**
   * Updates block with new values from mxCell
   * @param {Object} block - mxCell object
   */
  updateValues(block) {
    const geo = block.geometry;
    const { text, label } = this.extractTextAndLabels(block.value);

    this.x = geo.x / dc.UNIT_SIZE;
    this.y = geo.y / dc.UNIT_SIZE;
    this.width = geo.width / dc.UNIT_SIZE;
    this.height = geo.height / dc.UNIT_SIZE;
    this.text = text;
    this.label = label;
  }

  /**
   * Extracts text and label from message of mxCell
   * @param {String} value - Label message from mxCell
   * @returns {Object} - Object with keys text and label
   */
  extractTextAndLabels(value) {
    const labelRegex = /(\d+[a-z]*)/gi;
    const label = value.match(labelRegex)
      ? value.match(labelRegex)[0].replace(/(<\/?[a-z]+>)/gi, '')
      : '';
    const text = value.replace(labelRegex, '').replace(/(<\/?[a-z]+>)/gi, '');

    return { text, label };
  }

  /**
   * Updates block label based on figure number
   * @param {String} number - Fig number
   */
  updateNumber(number, isCallout = false) {
    const { label } = this;
    if (label.length > 0) {
      const labelParts = label.match(/[a-z]+|[^a-z]+/gi);
      const substring = labelParts ? labelParts[0].substring(labelParts[0].length - 2) : '';
      const letter = labelParts[1] ? labelParts[1] : '';

      this.label = `${number}${substring}${letter}`;

      if (isCallout) this.width = getTextWidth(this.label, dc.FONT_SIZE) / dc.UNIT_SIZE + 3;
    }
  }

  /**
   * Adds a link id to list of links on block
   * @param {String} linkId - valid UUID referencing a link
   */
  addLinkId(linkId) {
    const existingLink = this.links.find((l) => l.id === linkId);

    if (!existingLink) {
      this.links.push(linkId);
    }
  }

  removeLink(id) {
    const index = this.links.find((link) => link === id);
    this.links.splice(index, 1);
  }
}
