import config from '@/data/var.config.js';
import { DraftKeys as k } from '@/data/constants/vuexKeys';
import { UploadKeys as uk, TierLimits as tl } from '@/data/constants';
import { unpackDiagrams } from '@/support/utilities/unpackers';
import { formatErrorMessage } from '@/support/utilities';
import { postAlice } from '@/support/alice';
import { packClaims } from './packers';
import {
  ACTION_GENERATE_FAIL,
  ACTION_USED_CUSTOM,
  ACTION_USED_DEFAULT,
  CATEGORY_GENERATE,
  CATEGORY_PHRASE_CUSTOMIZATION,
} from '@/data/constants/AnalyticEventKeys';
import * as p from '@/data/constants/Phrases';

export function checkIfSubscribed(appData, userData, currentMonth) {
  return (
    !appData.subscribed &&
    userData.generatedDrafts >= config.DRAFT_LIMIT &&
    userData.month === currentMonth
  );
}

export function packRequestData(state, claims) {
  return {
    document: packClaims(claims),
    options: {
      ...state.activeProfile.phrases,
      verbose_steps: state.activeProfile.verbose_steps,
    },
  };
}

export function handleRequest(context, appData, userData, month) {
  const reachedLimit = checkIfSubscribed(appData, userData, month);

  if (reachedLimit && config.TIER_CHECKS) {
    showSubscribePrompt(context);
  } else if (notLoading(context)) {
    return sendRequestToAlice(context);
  }
}

export function notLoading({ rootState, state }) {
  return !rootState.upload[uk.UPLOADING] && !state[k.LOADING] && !state[k.INSERTING];
}

export function showSubscribePrompt({ commit }) {
  commit('toggleSubscribePrompt', { show: true, message: tl.DRAFT_LIMIT_TEXT });
}

export function sendRequestToAlice({ state, commit }, claims) {
  commit('setDraftState', { key: k.SHOW_PHRASE, val: true });
  commit('setLoading', true);

  const data = packRequestData(state, claims);

  return postAlice('generate_draft', data);
}

export function setWarning(commit) {
  commit('setWarn', true);
  commit('setLoading', false);
}

export function validateData(getters, claims) {
  return !getters.validClaims && !getters.outlineChanged && !claims;
}

export function unpackResponse(res, claims, cx) {
  const { figures: d, specification: description } = res.data;
  const diagrams = unpackDiagrams(d);

  cx.commit('setOutlineData', res.data);
  cx.commit('setDraftState', { key: 'clicked', val: false });
  cx.commit('setDraftState', { key: 'inserting', val: true });
  cx.commit('setDraftState', { key: 'step', val: 2 });
  cx.commit('setDiagrams', []);
  return Promise.all([
    cx.dispatch('loadClaims', claims),
    cx.dispatch('loadDiagrams', diagrams),
    cx.dispatch('loadDescription', description),
  ]).then(() => cx.commit('setDraftState', { key: 'clicked', val: false }));
}

export function updateAuth0Data(auth, appData, userData, month) {
  if (!appData.subscribed && config.TIER_CHECKS) {
    if (isNaN(userData.generatedDrafts)) {
      userData.generatedDrafts = 1;
    } else if (month === userData.month) {
      userData.generatedDrafts++;
    } else {
      userData.month = month;
      userData.generatedDrafts = 1;
    }

    return auth.patchUserMetadata({
      month,
      generatedDrafts: userData.generatedDrafts,
    });
  }
}

export function logEvents(app, cx) {
  const activePhrases = JSON.stringify(cx.state.activeProfile.phrases);
  const defaultPhrases = getDefaultPhrases();

  if (activePhrases === defaultPhrases) {
    app.$gtag.event(ACTION_USED_DEFAULT, {
      event_category: CATEGORY_PHRASE_CUSTOMIZATION,
    });
  } else {
    app.$gtag.customMap({ dimension2: 'phrases' });
    app.$gtag.event(ACTION_USED_CUSTOM, {
      event_category: CATEGORY_PHRASE_CUSTOMIZATION,
      phrases: activePhrases,
    });
  }
  if (!app.$auth.user?.metaData.draftTutorialOutputs) {
    app.$tours.homeTour.start();
    app.$auth.patchUserMetadata({ draftTutorialOutputs: true });
  }
}

function getDefaultPhrases() {
  const obj = {
    summary_intro: p.summary_intro,
    family_intro: p.family_intro,
    concept_intro: p.concept_intro,
    context_intro: p.context_intro,
    including: p.including,
    may_include: p.may_include,
    may_also_include: p.may_also_include,
  };

  return JSON.stringify(obj);
}

export function saveNewDocumentData(commit) {
  commit('saveClaims');
  commit('saveOutline');
  commit('setOutlineUpToDate', true);
}

export function handleServerRequestError(err, gtag, commit) {
  console.error(err);
  const res = err.response;

  gtag.event(ACTION_GENERATE_FAIL, {
    event_category: CATEGORY_GENERATE,
    event_label: formatErrorMessage(res?.data?.message, res?.data?.id),
  });

  commit('setDraftState', { key: k.INSERTING, val: false });
  commit('setDraftState', { key: k.LOADING, val: false });
  commit('error/handleError', err, { root: true });
}

export function unpackSubscriptionData(app) {
  const month = new Date().toLocaleString('default', { month: 'long' });
  const appData = app.$auth.user.appData;
  const userData = app.$auth.user.metaData;

  return { month, appData, userData };
}
