// eslint-disable-next-line no-control-regex
export const ZWNBSP_REGEX = /[^\x00-\x7F]/;
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const ZWNBSP = '\uFEFF';
export const S_ALPHA_NUMERIC_RE = /^[a-z\d]/i;
export const E_ALPHA_NUMERIC_RE = /[a-z\d]$/i;
export const ALPHA_NUMERIC_RE = /[a-z\d]/i;
export const NON_APLHA_NUMERIC_RE = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s]/;
export const ALL_HTML_TAGS = /<.+?>|<\/.+?>|&nbsp;/gi;
export const HTMLEndTags = /<\/(?:h1|h2|h3|p)+?>/gi;
