/**
 * Getters for draft Vuex
 */
import { packOutline } from '@/support/utilities';

export default {
  showDiagrams({ step }) {
    return step === 2;
    // return true;
  },

  showGenerate({ draft }) {
    return draft;
  },

  totalPoints({ outlines }) {
    let total = 0;

    function yeet(children) {
      total += children.length;
      children.forEach((child) => {
        if (child.children.length > 0) {
          yeet(child.children);
        }
      });
    }

    outlines.forEach((outline) => yeet(outline.points));
    return total;
  },

  initialOutline({ outlines }) {
    return (
      outlines.length === 1 &&
      outlines[0].points.length === 1 &&
      outlines[0].points[0].children.length === 0 &&
      outlines[0].points[0].text.length === 0
    );
  },

  outlineChanged({ outlines, savedOutlines }, getters) {
    if (getters.initialOutline) {
      return false;
    }

    const currentOutlines = JSON.stringify(packOutline(outlines));
    return currentOutlines !== savedOutlines;
  },

  claimsChanged({ claims, savedClaims }, getters) {
    if (getters.validClaims) {
      return claims !== savedClaims;
    }
    return false;
  },

  validClaims({ claims }) {
    return claims.trim().length > 0;
  },

  claimsAndOutlineDiverged(
    { diagramsFromOutline },
    { claimsChanged, outlineChanged, initialOutline }
  ) {
    return (
      (claimsChanged && outlineChanged && !initialOutline) ||
      diagramsFromOutline ||
      (!claimsChanged && outlineChanged)
    );
  },

  changesDetected({ step }, { validClaims, claimsChanged, outlineChanged }) {
    return (validClaims && step === 1) || claimsChanged || outlineChanged;
  },
};
