// Docs: https://gitbrent.github.io/PptxGenJS/docs/quick-start/
import Pptxgen from 'pptxgenjs';
import { convertPxToIn, calculateCalloutLinePoints } from './DiagramHelpers';
import * as dc from '../../data/constants/DiagramConstants';
import { fileDate, titleCase } from '@/support/utilities';

// --- Constants --- //
const PAGE_WIDTH = 8.5;
const PAGE_HEIGHT = 11;
const LINE_WIDTH = 1;
const LINE_ARROW = 'triangle';
const LINE_COLOR = '#000000';
const BLOCK_BACKGROUND_COLOR = '#FFFFFF';
const FONT_FACE = 'Times New Roman';
const P_TEXT_ALIGN = { align: 'left', valign: 'top' };
const C_TEXT_ALIGN = { align: 'center', valign: 'middle' };
const L_TEXT_ALIGN = { align: 'right', valign: 'middle' };
// --- End constants --- //

// --- Default export --- //
/**
 * Create a PowerPoint file and save it to the users files.
 *
 * @param {object[]} editors
 * @param {string} title
 */
export default function exportPowerPointFile(editors, title, truncate) {
  // Create powerpoint presentation
  const pres = new Pptxgen();

  // Define presentation props
  pres.defineLayout({ name: 'PatentPalDefault', width: PAGE_WIDTH, height: PAGE_HEIGHT });
  pres.layout = 'PatentPalDefault';

  addSlides(pres, editors, truncate);

  pres.writeFile({ fileName: `${fileDate()} - ${titleCase(title)} - Figs.pptx` });
}

// --- End default export --- //

// --- Internal functions --- //

function addSlides(pres, editors, truncate) {
  editors.forEach((editor) => {
    const { graph, illustration } = editor;
    const slide = pres.addSlide();

    addElements(slide, pres, graph, illustration, truncate);
  });
}

function addElements(slide, pres, graph, illustration, truncate) {
  const cells = Object.values(graph.model.cells);
  let isFlowchart = false;

  cells.forEach((cell) => {
    if (cell.style?.includes('shape=image')) {
      createImage(cell, slide, pres, illustration);
    } else if (cell.vertex) {
      isFlowchart = !isFlowchart ? cell.style === dc.BLOCK_PILL_STYLE : isFlowchart;
      const wrap = (isFlowchart || !truncate) && cell.style !== dc.FIG_STYLE;
      createBlock(cell, slide, pres, wrap);
    } else if (cell.edge) {
      createConnection(cell, slide, pres, graph);
    }
  });
}

function createBlock(cell, slide, pres, textWrap) {
  const text = formatText(cell.value);

  const shapeProps = formatShapeProps(cell, textWrap, pres);

  // We use addText in order to create a shape with text. addShape does not support text
  slide.addText(text, shapeProps);
}

function createConnection(cell, slide, pres, graph) {
  // Get cell state
  const { lineTail, lineHead } = determineArrowDirections(cell.style);

  const { begX, endY, begY, endX } = calculateLinePoints(cell, graph);

  // Add the line to the slide
  slide.addShape(pres.shapes.LINE, {
    lineTail,
    lineHead,
    x: begX,
    y: begY,
    w: endX,
    h: endY,
    line: { width: LINE_WIDTH },
  });
}

function createImage(cell, slide, pres, url) {
  const img = url.replace('data:', '');

  slide.addImage({
    data: img,
    type: 'contain',
    x: convertPxToIn(cell.geometry.x),
    y: convertPxToIn(cell.geometry.y),
    w: convertPxToIn(cell.geometry.width),
    h: convertPxToIn(cell.geometry.height),
  });
}

function formatShapeProps(cell, textWrap, pres) {
  const isCallout = cell.style.includes('shape=callout');
  const isParent = cell.style === dc.BLOCK_PARENT_STYLE;
  const isFig = cell.style === dc.FIG_STYLE;
  const isPill = cell.style === dc.BLOCK_PILL_STYLE;

  // Get width and height
  const w = convertPxToIn(cell.geometry.width);
  const h = convertPxToIn(cell.geometry.height);

  // Determine x and y coordinates
  const x = convertPxToIn(cell.geometry.x + dc.UNIT_SIZE);
  const y = convertPxToIn(cell.geometry.y);

  const shape = determineShape(isCallout, isPill, isFig, pres);
  const points = isCallout
    ? calculateCalloutLinePoints(
        { width: w, height: h, direction: cell.block.direction },
        convertPxToIn(dc.UNIT_SIZE)
      )
    : null;

  const { align, valign } = determineTextAlignment(isCallout, isParent, cell.block.direction);

  return {
    shape,
    w,
    h,
    x,
    y,
    align,
    valign,
    bold: isFig,
    italic: isFig,
    fontFace: FONT_FACE,
    fontSize: isFig ? 24 : 10,
    wrap: textWrap,
    ...(isPill && { rectRadius: 100 }),
    ...(!isFig && { line: { color: LINE_COLOR } }),
    ...(!isFig && !isCallout && { fill: { color: BLOCK_BACKGROUND_COLOR } }),
    ...(isCallout && { points }),
  };
}

function determineArrowDirections(style) {
  const lineTail = style === dc.STYLE_BIDIRECTED || style === dc.STYLE_OUTBOUND ? LINE_ARROW : null;
  const lineHead = style === dc.STYLE_BIDIRECTED || style === dc.STYLE_INBOUND ? LINE_ARROW : null;

  return { lineTail, lineHead };
}

function calculateLinePoints(cell, graph) {
  const state = graph.view.getState(cell, true);

  // get cell bounds, start, and end
  const bounds = state.cellBounds;
  const startPoint = state.absolutePoints[0];
  const endPoint = state.absolutePoints[state.absolutePoints.length - 1];

  // Calculate beginning position
  const begY = convertPxToIn(bounds.y);
  const begX = convertPxToIn(bounds.x + dc.UNIT_SIZE);

  // Calculate the difference between the start and end points
  const difX = convertPxToIn(endPoint.x - startPoint.x);
  const difY = convertPxToIn(endPoint.y - startPoint.y);

  // Calculate the end position
  const endY = difY ? convertPxToIn(bounds.height) : 0;
  const endX = difX ? convertPxToIn(bounds.width) : 0;

  return { begY, begX, endY, endX };
}

function formatText(str) {
  const textParts = str.split('<u>');

  // Clean HTML from strings
  const part1 = textParts[0].replace(/(<([^>]+)>)/gi, '').replace(/(<\/u>?)/gi, '');
  const part2 = textParts[1]?.replace(/(<([^>]+)>)/gi, '').replace(/(<\/u>?)/gi, '');

  return part1
    ? [{ text: part1 }, { text: part2, options: { underline: true } }]
    : [{ text: part2, options: { underline: true } }];
}

function determineTextAlignment(isCallout, isParent, direction) {
  if (isCallout) {
    if (direction === 0 || direction === 2 || direction === 4) {
      return L_TEXT_ALIGN;
    }

    if (direction === 1 || direction === 3) {
      return { align: 'left', valign: 'middle' };
    }
  }

  if (isParent) {
    return P_TEXT_ALIGN;
  }

  return C_TEXT_ALIGN;
}

function determineShape(isCallout, isPill, isFig, pres) {
  return isPill
    ? pres.shapes.ROUNDED_RECTANGLE
    : isCallout
    ? pres.shapes.CUSTOM_GEOMETRY
    : isFig
    ? null
    : pres.shapes.RECTANGLE;
}
