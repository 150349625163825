<template>
  <div></div>
</template>

<script>
import { ACTION_LOGIN } from '@/data/constants/AnalyticEventKeys';
import { mapActions } from 'vuex';

export default {
  async mounted() {
    try {
      const res = await this.$auth.handleAuthentication();
      if (res) {
        try {
          await this.$auth.refresh(true);
        } catch (err) {
          console.error(err);
        } finally {
          this.$gtag.event(ACTION_LOGIN);
          const signedUp = window.localStorage.getItem('signedUp');
          if (signedUp) {
            window.localStorage.removeItem('signedUp');
            this.navigateToSubscribePage({ login: 'true' });
          } else {
            await this.$router.replace('/');
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  },

  methods: {
    ...mapActions('draft', ['navigateToSubscribePage']),
  },
};
</script>
