<!--
   This is where Vue Tour logic is kept for the tutorial.
   Component loaded in App.vue
   Start Tutorial called in /components/HelpMenu.vue

   Steps: defines the steps tour will go through. uses selectors ['.tut1' - '.tut5', '#download']
   Callbacks: (not in use) Can be used for actions before and after each step
   Options: Overwrites naming of the buttons
-->
<template>
  <div id="tutorial">
    <v-tour name="homeTour" :steps="steps" :callbacks="tourCallbacks" :options="tourOptions">
      <template slot-scope="tour">
        <TutorialCard :tour="tour" @skip="skipTutorial" />
      </template>
    </v-tour>
    <v-overlay :z-index="100" :value="overlay"></v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { tutorialData } from '@/data/constants';
import { DraftKeys as dk } from '@/data/constants/vuexKeys';
import {
  ACTION_TUTORIAL_FINISHED,
  ACTION_TUTORIAL_QUIT,
  CATEGORY_TUTORIAL,
} from '@/data/constants/AnalyticEventKeys';
import TutorialCard from '@/components/tutorial/TutorialCard';
import { MENU_TOOLTIP_ID } from '@/data/constants/TooltipConstants';

export default {
  components: { TutorialCard },
  data() {
    return {
      overlay: false,
      tourOptions: {
        labels: {
          buttonSkip: 'Cancel',
          buttonPrevious: 'Back',
          buttonNext: 'Next',
          buttonStop: 'Next',
        },
      },
    };
  },

  computed: {
    ...mapState({
      step: (state) => state.draft[dk.STEP],
      claims: (state) => state.draft[dk.CLAIMS],
    }),
    ...mapGetters('draft', ['showGenerate']),

    tourCallbacks() {
      return {
        onStart: () => {
          this.setDraftState({ key: dk.VIEWING_TUTORIAL, val: true });
          this.toggleOverlay(true);
        },

        onSkip: () => {
          this.setDraftState({ key: dk.VIEWING_TUTORIAL, val: false });
          this.toggleOverlay(false);
          this.toggleTooltip(MENU_TOOLTIP_ID);
        },

        onFinish: () => {
          this.$gtag.event(ACTION_TUTORIAL_FINISHED, { event_category: CATEGORY_TUTORIAL });
          this.toggleOverlay(false);
          this.setDraftState({ key: 'showX', val: false });
        },
      };
    },

    /**
     * @returns {Array}
     */
    steps() {
      if (!this.showGenerate) return tutorialData.newDocumentSteps;
      if (this.showGenerate) {
        if (this.step === 1) {
          if (this.claims.length > 0) return tutorialData.filledClaimSteps;
          return tutorialData.claimSteps;
        }
        if (this.step === 2) {
          return tutorialData.generateSteps;
        }
      }
      return [];
    },
  },
  methods: {
    ...mapMutations({
      toggleTooltip: 'tooltip/toggleTooltip',
      setDraft: 'draft/setDraft',
      setDraftState: 'draft/setDraftState',
    }),

    skipTutorial({ e, tour }) {
      const event_label = `on step ${tour.currentStep + 1} of ${
        this.showGenerate
          ? `generate view on step ${this.step} of generate process`
          : 'dashboard view'
      }`;
      this.$gtag.event(ACTION_TUTORIAL_QUIT, { event_category: CATEGORY_TUTORIAL, event_label });
      tour.skip();
    },

    /**
     * Shows/hides overlay. Can optionally provide a boolean message to manually set the overlay
     * @param {Boolean} [val] - If true shows overlay, if false hides overlay.
     */
    toggleOverlay(val = !this.overlay) {
      this.overlay = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
