<template>
  <v-overlay :value="logoutPrompt">
    <PromptCard
      title="Log out"
      text="Are you sure you want to log out? You will lose your current progress."
      okay-text="Log out"
      @okay="logout"
      @cancel="closePrompt"
    />
  </v-overlay>
</template>

<script>
import PromptCard from './PromptCard';
import { mapMutations, mapState } from 'vuex';
import { DraftKeys as dk } from '@/data/constants/vuexKeys';

export default {
  name: 'LogoutPrompt',
  components: {
    PromptCard,
  },

  computed: {
    ...mapState('draft', { logoutPrompt: (state) => state[dk.LOGOUT_PROMPT] }),
  },

  methods: {
    ...mapMutations('draft', { setState: 'setDraftState', setLoading: 'setLoading' }),

    closePrompt() {
      this.setState({ key: dk.LOGOUT_PROMPT, val: false });
    },

    logout() {
      this.setState({ key: dk.HANDLED_PROMPT, val: true });
      this.setState({ key: dk.LOGOUT_PROMPT, val: false });
      this.setState({ key: dk.SHOW_PHRASE, val: false });
      this.setLoading(true);
      try {
        this.$auth.logout({
          returnTo: window.location.origin,
          localOnly: false,
        });
      } catch (err) {
        this.setLoading(false);
        console.error(err);
      }
    },
  },
};
</script>

<style scoped></style>
