<template>
  <v-overlay :value="show">
    <v-sheet width="640" height="750" max-height="100%" max-width="100%" light rounded="">
      <v-btn icon class="closeButton" @click="closeProfile">
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <v-form class="d-flex flex-column align-center pt-16">
        <h2 class="text-h4">{{ text.GEN_PROFILE }}</h2>
        <label for="imageInput" class="userImage mt-8 mb-10">
          <v-hover v-slot="{ hover }">
            <v-avatar
              size="150"
              :color="hover || !picture.includes('gravatar') ? 'grey lighten-4' : 'primary'"
            >
              <v-img
                v-if="!hover && !picture.includes('gravatar')"
                :src="picture"
                :alt="`${firstName}'s profile picture`"
              />
              <span
                v-if="!hover && picture.includes('gravatar')"
                class="light-text"
                style="font-size: 60px"
                >{{ `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}` }}</span
              >

              <v-fade-transition hide-on-leave>
                <v-icon v-if="hover" size="50">mdi-file-image</v-icon>
              </v-fade-transition>
            </v-avatar>
          </v-hover>
        </label>
        <input
          type="file"
          id="imageInput"
          class="imageInput"
          accept="image/*"
          @change="displaySelectedImage"
        />
        <div>
          <v-text-field
            v-model="firstName"
            :label="text.FORM_FIRST"
            outlined
            dense
            color="blue lighten-3"
          ></v-text-field>
          <v-text-field
            v-model="lastName"
            :label="text.FORM_LAST"
            outlined
            dense
            color="blue lighten-3"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="nickname"
            dense
            outlined
            label="Display name"
            color="blue lighten-3"
          ></v-text-field>
          <v-text-field
            v-model="company"
            dense
            outlined
            label="Company"
            color="blue lighten-3"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="email"
            :label="text.GEN_EMAIL"
            hint="Login required after changing email"
            :disabled="!showEmailInput"
            dense
            outlined
            color="blue lighten-3"
          ></v-text-field>
          <v-text-field
            v-model="phoneNumber"
            type="tel"
            ref="phoneInput"
            dense
            outlined
            label="Phone number"
            color="blue lighten-3"
          />
        </div>
        <div>
          <v-btn
            type="button"
            @click="changePassword"
            color="primary"
            class="resetPasswordButton"
            :disabled="!showEmailInput"
            text
          >
            {{ text.GEN_RESET }}
          </v-btn>
        </div>
        <v-btn
          @click="coordinateUpdate"
          :disabled="nothingChanged || loading"
          color="primary"
          :loading="loading"
          rounded
          width="180"
          height="40"
          class="mt-10 mb-4"
        >
          Save
        </v-btn>
        <v-dialog width="385" v-model="dialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-on="on" v-bind="attrs" color="primary"> Delete account </v-btn>
          </template>
          <PromptCard
            title="Are you sure?"
            text="This action is permanent and you will need to create a new account if you wish to continue using the app later."
            okayText="Delete my account"
            @cancel="dialog = false"
            @okay="deleteAccount"
          />
        </v-dialog>
      </v-form>

      <Message
        color="error"
        v-model="error"
        :message="errorMessage"
        @close="error = false"
        absolute
      />
      <Message
        color="success"
        v-model="success"
        :message="successMessage"
        @close="success = false"
        absolute
      />
    </v-sheet>
  </v-overlay>
</template>

<script>
import { updateUser, postImageToS3, postToAuth0, deleteUser } from '@/support/alice';
import { readDataUrl } from '@/support/utilities';
import { AUTH_CLIENT_ID, REALM } from '@/data/var.config';
import * as text from '@/data/constants/messages';
import { mapState, mapMutations } from 'vuex';
import { DraftKeys as dk } from '@/data/constants/vuexKeys';
import PromptCard from './PromptCard';
import Message from '@/components/global/Message';
import {
  ACTION_CLOSE,
  ACTION_DELETE_ACCOUNT,
  ACTION_OPEN,
  ACTION_REQUEST_PASSWORD_RESET,
  ACTION_UPDATE_PROFILE,
  CATEGORY_PROFILE_OVERLAY,
} from '@/data/constants/AnalyticEventKeys';

export default {
  name: 'ProfileOverlay',
  components: {
    Message,
    PromptCard,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      nickname: '',
      company: '',
      email: '',
      picture: '',
      phone: '',
      error: false,
      success: false,
      loading: false,
      passwordLoading: false,
      successMessage: '',
      errorMessage: '',
      overlay: false,
      dialog: false,
      text,
    };
  },
  mounted() {
    this.$gtag.event(ACTION_OPEN, { event_category: CATEGORY_PROFILE_OVERLAY });
    if (this.user) {
      this.firstName = this.splitFirstName();
      this.lastName = this.splitLastName();
      this.nickname = this.user.nickname;
      this.email = this.user.email;
      this.picture = this.user.picture;
      this.company = this.user.metaData.company;
      this.phone = this.user.metaData.phone;
    }
  },
  methods: {
    ...mapMutations('draft', {
      toggleProfile: 'toggleProfileOverlay',
      setDraftState: 'setDraftState',
      setLoading: 'setLoading',
    }),

    closeProfile() {
      this.$gtag.event(ACTION_CLOSE, { event_category: CATEGORY_PROFILE_OVERLAY });
      this.toggleProfile(false);
    },

    splitFirstName() {
      return this.$auth.user.name.split(' ')[0];
    },

    splitLastName() {
      return this.$auth.user.name.split(' ')[1];
    },

    async coordinateUpdate() {
      try {
        await this.handleUpdate();
      } catch (err) {
        this.handleError(err);
      }
    },

    async handleUpdate() {
      this.loading = true;
      const id = this.user.sub;
      const { firstName, lastName, nickname, phone, company } = this;
      const imageInput = document.getElementById('imageInput');
      if (imageInput.files.length > 0) {
        this.picture = await this.uploadImage(imageInput.files[0]);
      }
      await updateUser(
        id,
        firstName,
        lastName,
        nickname,
        company,
        phone,
        this.picture,
        this.user.appData.stripe_id,
        this.showEmailInput ? (this.email === this.user.email ? null : this.email) : null
      );
      await this.$auth.refresh();
      this.toggleSnackbar(true, 'Your profile has been updated successfully!');
      this.$gtag.event(ACTION_UPDATE_PROFILE, { event_category: CATEGORY_PROFILE_OVERLAY });
    },

    handleError(err) {
      if (err.response) {
        this.toggleSnackbar(false, err.response.data.errorMessage);
      } else {
        const message =
          'An error occurred. Please refresh the page and try again. If error persists please contact support@patentpal.com';
        this.toggleSnackbar(false, message);
        console.error(err);
      }
    },

    toggleSnackbar(succeeded, message) {
      if (succeeded) {
        this.successMessage = message;
        this.success = true;
      } else {
        this.error = true;
        this.errorMessage = message;
      }
      this.loading = false;
      this.passwordLoading = false;
    },

    updateProgress(progress) {
      console.log(progress);
    },

    async uploadImage(file) {
      try {
        const res = await postImageToS3(file, this.updateProgress);
        return `https://d9utf01xeia59.cloudfront.net/${res.key}`;
      } catch (err) {
        this.handleError();
      }
    },

    async changePassword() {
      try {
        this.passwordLoading = true;
        const res = await postToAuth0('dbconnections/change_password', {
          client_id: AUTH_CLIENT_ID,
          email: this.user.email,
          connection: REALM,
        });
        this.$gtag.event(ACTION_REQUEST_PASSWORD_RESET, {
          event_category: CATEGORY_PROFILE_OVERLAY,
        });
        this.toggleSnackbar(true, res.data);
      } catch (err) {
        this.handleError(err);
      }
    },

    displaySelectedImage(e) {
      const file = e.target.files[0];
      readDataUrl(file, (result) => {
        this.picture = result;
      });
    },

    logout() {
      this.$auth.logout();
    },

    async deleteAccount() {
      try {
        this.setDraftState({ key: dk.HANDLED_PROMPT, val: true });
        this.setDraftState({ key: dk.SHOW_PHRASE, val: false });
        this.setLoading(true);
        this.dialog = false;
        await deleteUser(this.user.sub, this.user.appData.stripe_id);
        this.$gtag.event(ACTION_DELETE_ACCOUNT, { event_category: CATEGORY_PROFILE_OVERLAY });
        this.$auth.logout();
      } catch (err) {
        console.error(err);
        this.setLoading(false);
        this.dialog = false;
        this.toggleSnackbar(
          false,
          'Deletion failed. Please contact support@patentpal.com for assistance'
        );
      }
    },

    validateNumber(n) {
      const number = n.replace(/[^\d]/g, '');
      const length = number.length;
      this.phone = 'nothing';

      if (length < 4) {
        this.$nextTick(() => {
          this.phone = number;
        });
      } else if (length < 7) {
        this.$nextTick(() => {
          this.phone = `(${number.slice(0, 3)}) ${number.slice(3)}`;
        });
      } else if (length < 11) {
        this.$nextTick(() => {
          this.phone = `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
        });
      } else {
        this.$nextTick(() => {
          this.phone = number;
        });
      }
    },
  },

  computed: {
    ...mapState('draft', {
      show: (state) => state[dk.PROFILE_OVERLAY],
    }),

    phoneNumber: {
      /**
       * @returns {string}
       */
      get() {
        return this.phone;
      },

      set(val) {
        this.validateNumber(val);
      },
    },

    /**
     * @returns {Boolean} True or False if depending on account type of user
     */
    showEmailInput() {
      if (this.user) {
        const idKey = this.user.sub.split('|')[0];
        return idKey === 'auth0';
      }
      return false;
    },

    /**
     * @returns {Object} Auth0 User
     */
    user() {
      return this.$auth.user;
    },

    /**
     * @returns {Boolean}
     */
    nothingChanged() {
      if (this.user) {
        return (
          `${this.firstName} ${this.lastName}` === this.user.name &&
          this.nickname === this.user.nickname &&
          this.email === this.user.email &&
          this.picture === this.user.picture &&
          this.phone === this.user.metaData.phone &&
          this.company === this.user.metaData.company
        );
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.supportEmail {
  color: yellow;
}

.closeButton {
  position: absolute;
  right: 0;
}

.container {
  width: 400px;
  position: relative;
  > .v-btn {
    padding: 0 !important;
  }
}

.imageInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.userImage {
  &:hover {
    cursor: pointer;
  }
}

.v-form {
  padding: 70px;
  div {
    display: flex;
    width: 100%;
    .v-text-field {
      &:nth-child(1) {
        margin-right: 10px;
      }
      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
}

.resetPasswordButton {
  display: flex;
  //width: 240px;
  height: 40px;
  align-items: center;
  padding-left: 12px !important;
  margin-top: -8px;
  color: $primary;
  &:disabled {
    color: $gray;
  }
}
</style>
