<template>
  <section class="root">
    <div class="temp">
      <div class="top">
        <h1 class="header mt-0">{{ title }}</h1>
      </div>
      <div class="options-container">
        <div>
          <div class="box blankDocument" @click="redirectToDraft"></div>
          <p>{{ blank }}</p>
        </div>
        <div>
          <UploadFileInput />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { UploadFileInput } from '@/components/upload';
import { DOC_BLANK, DOC_TITLE } from '@/data/constants/messages';
import { mapMutations } from 'vuex';

export default {
  name: 'Dashboard',
  components: { UploadFileInput },
  data() {
    return {
      blank: DOC_BLANK,
      title: DOC_TITLE,
    };
  },

  watch: {
    '$auth.user': {
      immediate: true,
      handler(user) {
        if (user && !user?.metaData.tutorial) {
          this.$nextTick(() => {
            this.$tours.homeTour.start();
            this.$auth.patchUserMetadata({ tutorial: true });
          });
        }
      },
    },
  },

  methods: {
    ...mapMutations('draft', ['resetState', 'setDraft']),
    redirectToDraft() {
      this.resetState();
      this.setDraft(true);
    },
  },
};
</script>

<style scoped lang="scss">
p {
  margin: 32px 0px;
  color: $app-text-color;
}

.top {
  display: flex;
  align-items: center;
}

.temp {
  width: 500px;
  max-width: 100%;
}

.root {
  width: 500px;
  //width: fit-content;
  margin: auto;
  padding-top: 48px;
  max-width: 90vw;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.options-container {
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
  @media screen and (max-width: 399px) {
    justify-content: center;
  }
}

.header {
  color: $app-text-color;
  margin: 50px 0px 40px 0px;
}

.box {
  background-color: #fff;
  width: 180px;
  max-width: 100% !important;
  aspect-ratio: 8.5/11;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 20px;
  &:hover {
    background-color: #f6f6f6;
  }
}
</style>
