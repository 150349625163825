<!--
  Upload Overlay, visible on File Upload or Diagram Generation.
  Also has logic to check if a file has been uploaded already.
  Triggered by MainMenu#handleFileUpload and ProgressBar#verifyGetDiagrams->ClaimsAndGraphPanel#handlegenerateDraftFromClaims
-->

<template>
  <v-overlay
    :value="showOverlay"
    :opacity="inserting ? 0 : 0.75"
    :class="inserting ? 'loadingCursor' : !showCard && dropUpload ? 'prevent' : ''"
    @dragleave.native.prevent="setDropState"
    @drag.prevent
    @dragover.prevent
    @click="setDraftState({ key: ['clicked', 'sequential'], val: [true, false] })"
  >
    <!--    <v-fade-transition hide-on-leave>-->
    <img
      v-if="showImage"
      src="@/data/static/Drag and Drop Icon.svg"
      alt="drag and drop"
      @dragenter.prevent
      @dragover.prevent
      @dragleave.prevent
    />
    <!--    </v-fade-transition>-->
    <v-fade-transition hide-on-leave>
      <UploadProgress v-if="uploading" />
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <Loading v-if="loading && !inserting" />
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <PromptCard
        v-if="showCard"
        title="Overwrite document?"
        text="You will lose your progress on the current document when you start a new one."
        @okay="handleFileUpload"
        @cancel="setDropState"
        colors
        okayText="Overwrite"
      />
    </v-fade-transition>
  </v-overlay>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import UploadProgress from './UploadProgress.vue';
import Loading from '../global/Loading.vue';
import PromptCard from '../global/PromptCard';
import { UploadKeys as UK, ErrorKeys as EK } from '@/data/constants';
import { DraftKeys as dk } from '@/data/constants/vuexKeys';
import { unpackClaims } from '@/support/utilities/unpackers';

export default {
  components: {
    UploadProgress,
    Loading,
    PromptCard,
  },
  name: 'UploadOverlay',
  data() {
    return {
      ignore: false,
      promptButtons: [
        {
          text: 'Cancel',
          action: 'Cancel',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      dropUpload: (state) => state[UK.MODULE_NAME][UK.DROP],
      file: (state) => state[UK.MODULE_NAME][UK.FILE],
      uploading: (state) => state[UK.MODULE_NAME][UK.UPLOADING],
      loading: (state) => state.draft[dk.LOADING],
      claims: (state) => state.draft[dk.CLAIMS],
      step: (state) => state.draft[dk.STEP],
      prompt: (state) => state[UK.MODULE_NAME][UK.DIALOG],
      inserting: (state) => state.draft[dk.INSERTING],
    }),
    ...mapGetters('draft', ['showGenerate']),
    /**
     * @returns {Boolean}
     */
    showCard() {
      return (
        (this.file &&
          !this.uploading &&
          this.claims.length > 0 &&
          !this.ignore &&
          !this.inserting) ||
        this.prompt
      );
    },

    /**
     * @returns {Boolean}
     */
    showOverlay() {
      return this.dropUpload || this.loading || this.uploading || this.inserting;
    },

    /**
     * @returns {Boolean}
     */
    showImage() {
      return !this.file && !this.loading && !this.uploading && !this.prompt;
    },
  },
  methods: {
    ...mapMutations({
      resetState: 'draft/resetState',
      setClaims: 'draft/setClaims',
      changeClaims: 'draft/changeClaims',
      setUploadState: 'upload/setUploadState',
      toggleDialog: 'upload/toggleDialog',
      endUpload: 'upload/endUpload',
      setLoading: 'draft/setLoading',
      setError: 'error/setErrorState',
      setDraft: 'draft/setDraft',
      setDraftState: 'draft/setDraftState',
    }),
    ...mapActions({
      generateDraft: 'draft/generateDraft',
      uploadFile: 'upload/uploadFile',
      loadClaims: 'draft/loadClaims',
    }),

    handleDrag() {
      this.setUploadState({ key: UK.DROP, val: true });
    },

    setDropState() {
      this.setUploadState({ key: UK.DROP, val: false });
      this.setUploadState({ key: UK.DIALOG, val: false });
      this.setUploadState({ key: UK.FILE, val: null });
    },

    startNewDocument() {
      this.toggleDialog({ val: false });
      this.resetState();
    },

    async handleFileUpload() {
      document.activeElement.blur();
      if (this.file !== null) {
        try {
          const doc = await this.uploadFile();
          const claims = unpackClaims(doc);
          this.endUpload();
          if (this.step === 2) {
            this.setDraftState({ key: dk.SEQUENTIAL, val: true });
            await this.generateDraft(claims);
            this.setDraftState({ key: dk.SEQUENTIAL, val: false });
          } else {
            await this.loadClaims(claims);
          }
          if (!this.showGenerate) {
            this.setDraft(true);
          }
        } catch (err) {
          console.error(err);
          this.setError({
            key: EK.ERROR,
            val: true,
            message: EK.UPLOAD_ERROR,
          });
          this.endUpload();
        }
      } else {
        this.startNewDocument();
      }
    },
  },

  watch: {
    file: {
      handler(file) {
        this.ignore = false;
        if (this.claims.length === 0 && file !== null) {
          this.handleFileUpload();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.loadingCursor {
  cursor: wait;
}

img {
  //border: 2px solid red;
  //background: white;
  position: relative;
}
</style>
