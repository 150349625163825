import { Outline } from '@/data/Outline';
import { packDiagrams, packOutline, unpackers } from '@/support/utilities';
import { unpackDiagrams } from '@/support/utilities/unpackers';
import { DraftKeys as k } from '@/data/constants/vuexKeys';
import { CookieKeys as c, DiagramConstants as dc, TierLimits as tl } from '@/data/constants';
import { decrypt, encrypt } from '@/support/utilities/encryption';

/**
 * Mutations on state for draft Vuex
 */
export default {
  setDraftState(state, { key, val }) {
    if (Array.isArray(key)) {
      key.forEach((k, i) => {
        state[k] = val[i];
      });
    }
    state[key] = val;
  },

  toggleSubscribePrompt(state, { show = !state.subscribePrompt, message = tl.DEFAULT_TEXT }) {
    if (show) {
      state.subscribePromptMessage = message;
    }

    state.subscribePrompt = show;
  },

  setDraft(state, val) {
    state.draft = val;
  },

  alterActiveProfile(state, { key, val }) {
    state.activeProfile.phrases[key] = val;
  },

  setActiveProfile(state, val) {
    const hasTruncateText = 'truncateText' in val;
    const hasVerboseSteps = 'verbose_steps' in val;
    if (!hasTruncateText) {
      val.truncateText = true;
    }

    if (!hasVerboseSteps) {
      val.verbose_steps = true;
    }

    state.activeProfile = val;
  },

  toggleTruncateText(state) {
    state.activeProfile.truncateText = !state.activeProfile.truncateText;
  },

  toggleTooltip(state) {
    state[k.SHOW_TOOLTIP] = true;
    const interval = setInterval(() => {
      if (!state[k.HOVERING_TOOLTIP]) {
        state[k.SHOW_TOOLTIP] = false;
        clearInterval(interval);
      }
    }, 2500);
  },

  saveClaims(state) {
    state[k.SAVED_CLAIMS] = state.claims;
  },

  saveOutline(state) {
    state[k.SAVED_OUTLINES] = JSON.stringify(packOutline(state.outlines));
  },

  setOutlineUpToDate(state, val) {
    state[k.OUTLINE_UP_TO_DATE] = val;
  },

  setOptOut(state, val) {
    state[k.TUTORIAL_OPT_OUT] = val;
  },

  incrementStep(state) {
    if (state[k.STEP] < 2) state[k.STEP]++;
    if (state[k.STEP] > state[k.FURTHEST_STEP]) {
      state[k.FURTHEST_STEP] = state[k.STEP];
    }
  },

  decrementStep(state) {
    if (state[k.STEP] > 1) state[k.STEP]--;
  },

  setClaimsChanged(state, val) {
    state[k.CLAIMS_CHANGED] = val;
  },

  setGraphChanged(state, val) {
    state[k.OUTLINE_CHANGED] = val;
  },

  setLoading(state, val) {
    state[k.LOADING] = val;
  },

  setDiagrams(state, val) {
    state[k.DIAGRAMS] = val;
  },

  setClaims(state, doc) {
    doc = typeof doc === 'string' ? doc.split('\n') : doc;
    state[k.CLAIMS] = doc.reduce((string, span) => {
      string += `<p>${span}</p>`;
      return string;
    }, '');

    state[k.CLAIMS_CHANGED] = true;
    state[k.OUTLINE_UP_TO_DATE] = false;
  },

  setDescription(state, doc) {
    state[k.DESCRIPTION] = unpackers.unpackDescription(doc);
  },

  changeDescription(state, val) {
    state[k.DESCRIPTION] = val;
  },

  changeClaims(state, val) {
    state[k.CLAIMS] = val;
  },

  clearClaims(state) {
    state[k.CLAIMS] = '';
  },

  resetState(state) {
    if (!state.uploading) state.step = 1; // conditionally reset to step 1
    state[k.CLAIMS_CHANGED] = false;
    state[k.OUTLINE_CHANGED] = false;
    state[k.LOADING] = false;
    state[k.OUTLINE_UP_TO_DATE] = false;
    state[k.GET_DIAGRAMS_FROM_CLAIMS] = false;
    state[k.DIAGRAMS] = [];
    state[k.OUTLINES] = [
      new Outline({ tags: [], concepts: [], points: [{ text: '', children: [] }] }),
    ];
    state[k.CLAIMS] = '';
    state[k.DESCRIPTION] = '';
    state[k.TUTORIAL_OPT_OUT] = false;
    state[k.SAVED_CLAIMS] = null;
    state[k.SAVED_OUTLINES] = null;
    state[k.FURTHEST_STEP] = 1;
  },

  updateDiagramBlock(state, { block, index, number }) {
    const diagram = state[k.DIAGRAMS][index];
    if (number) diagram.number = number;
    diagram.blocks.forEach((b) => {
      if (b.id === block.id) {
        b.updateValues(block);
      }
      if (number) {
        b.updateNumber(parseInt(number).toString(), !b.shape === dc.SHAPE_LABEL);
      }
    });
  },

  addNewLink(state, { link, index }) {
    const diagram = state[k.DIAGRAMS][index];
    const existingLink = diagram.links.find((l) => l.id === link.id);
    if (!existingLink) {
      diagram.createNewLink(link);
    }
  },

  addNewBlock(state, { block, index }) {
    const diagram = state[k.DIAGRAMS][index];
    diagram.createNewBlock(block);
  },

  removeLink(state, { id, index }) {
    state[k.DIAGRAMS][index].removeLink(id);
  },

  removeBlock(state, { id, index }) {
    state[k.DIAGRAMS][index].removeBlock(id);
  },

  toggleProfileOverlay(state, val) {
    state[k.PROFILE_OVERLAY] = val;
  },

  setOutlineData(state, val) {
    state[k.OUTLINES] = val.outlines.map((o) => {
      return new Outline(o);
    });
  },

  deleteOutline(state, val) {
    if (state[k.OUTLINES].length > 1) {
      const index = state[k.OUTLINES].findIndex((outline) => outline.id === val);
      state[k.OUTLINES].splice(index, 1);
    }
  },

  addNewOutline(state) {
    const newOutline = new Outline({
      tags: [],
      concepts: [],
      points: [{ text: '', children: [] }],
    });

    state[k.OUTLINES].push(newOutline);
  },

  clearOutlines(state) {
    const newOutline = new Outline({
      tags: [],
      concepts: [],
      points: [{ text: '', children: [] }],
    });

    state[k.OUTLINES] = [newOutline];
  },

  addNewConcept(state, { index, val }) {
    state[k.OUTLINES][index].concepts.push(val);
  },

  removeConcept(state, { index, val }) {
    state[k.OUTLINES][index].concepts = state.outlines[index].concepts.filter(
      (concept) => concept.id !== val
    );
  },

  setOutlineFromClaims(state, val) {
    state[k.OUTLINE_FROM_CLAIMS] = val;
  },

  setFocusedTag(state, val) {
    state[k.FOCUSED_TAG] = val;
  },

  saveGeneratedDraft(state) {
    if (state[k.STEP] < 2) state[k.STEP]++;
    if (state[k.STEP] > state[k.FURTHEST_STEP]) state[k.FURTHEST_STEP] = state[k.STEP];
    state[k.LOADING] = false;
    state[k.INSERTING] = false;
  },

  handleError(state, err) {
    console.error(err);
    state.error = true;
    state[k.LOADING] = false;
    state[k.INSERTING] = false;
  },

  addParagraph(state, val) {
    state[k.DESCRIPTION] += val;
  },

  saveUserContent(state) {
    const session = window.sessionStorage;
    if (state.claims.trim().length > 0 || state.step > 1) {
      const content = {
        [k.CLAIMS]: state[k.CLAIMS],
        [k.FURTHEST_STEP]: state[k.FURTHEST_STEP],
        [k.STEP]: state[k.STEP],
        [k.DIAGRAMS]: packDiagrams(state[k.DIAGRAMS]),
        [k.DESCRIPTION]: state[k.DESCRIPTION],
        [k.OUTLINES]: packOutline(state[k.OUTLINES]),
        [k.SAVED_OUTLINES]: state[k.SAVED_OUTLINES],
        [k.SAVED_CLAIMS]: state[k.SAVED_CLAIMS],
      };

      const jsonString = JSON.stringify(content);
      const cy = encrypt(jsonString);
      session.setItem(c.USER_CONTENT, cy);
    }
    state[k.HANDLED_PROMPT] = true;
  },

  getUserContent(state) {
    const session = window.sessionStorage;
    const cy = session.getItem(c.USER_CONTENT);
    if (cy) {
      const content = decrypt(cy);
      const parsed = JSON.parse(content);

      if (parsed[k.STEP] === 2) {
        state[k.SEQUENTIAL] = false;
      }
      state[k.CLAIMS] = parsed[k.CLAIMS];
      state[k.DIAGRAMS] = unpackDiagrams(parsed[k.DIAGRAMS]);
      state[k.OUTLINES] = parsed[k.OUTLINES].map((o) => {
        return new Outline(o);
      });
      state[k.DESCRIPTION] = parsed[k.DESCRIPTION];
      state[k.STEP] = parsed[k.STEP];
      state[k.FURTHEST_STEP] = parsed[k.FURTHEST_STEP];
      state[k.SAVED_OUTLINES] = parsed[k.SAVED_OUTLINES];
      state[k.SAVED_CLAIMS] = parsed[k.SAVED_CLAIMS];
      session.removeItem(c.USER_CONTENT);
    }
  },
};
