<template>
  <transition name="fade">
    <v-step
      v-if="tour.steps[tour.currentStep]"
      :key="tour.currentStep"
      :step="tour.steps[tour.currentStep]"
      :previous-step="tour.previousStep"
      :next-step="tour.nextStep"
      :stop="tour.stop"
      :skip="tour.skip"
      :finish="tour.finish"
      :is-first="tour.isFirst"
      :is-last="tour.isLast"
      :labels="tour.labels"
    >
      <template>
        <div slot="content">
          <div class="buttonContainer">
            <v-btn v-if="showX" icon @click="skipTutorial" small><v-icon>mdi-close</v-icon></v-btn>
          </div>
          <p>{{ tour.steps[tour.currentStep].content }}</p>
        </div>
        <v-card-actions slot="actions">
          <v-btn text v-if="!tour.isFirst" @click="tour.previousStep">
            <v-icon left>mdi-chevron-left</v-icon>
            Back
          </v-btn>
          <v-spacer />
          <v-btn v-if="!tour.isLast" text @click="tour.nextStep">
            Next
            <v-icon right> mdi-chevron-right </v-icon>
          </v-btn>
          <v-btn v-if="tour.isLast" text @click="tour.finish"> Okay </v-btn>
        </v-card-actions>
      </template>
    </v-step>
  </transition>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { DraftKeys as dk } from '@/data/constants/vuexKeys';

export default {
  name: 'TutorialCard.vue',
  props: {
    tour: Object,
    buttonText: {
      type: String,
      default: 'Next',
    },
  },

  computed: {
    ...mapState({ step: (state) => state.draft[dk.STEP], showX: (state) => state.draft.showX }),
    ...mapGetters('draft', ['showGenerate']),
  },

  methods: {
    ...mapMutations({ changeClaims: 'draft/changeClaims', setDraftState: 'draft/setDraftState' }),

    skipTutorial(e) {
      const { tour } = this;
      this.$emit('skip', { e, tour });
      this.setDraftState({ key: 'showX', val: false });
    },
  },
};
</script>

<style scoped lang="scss">
.buttonContainer {
  display: flex !important;
  width: 100%;
  justify-content: flex-end;
  padding: 5px 5px 0 0;
  height: 33px;
}

.v-icon--right,
.v-icon--left {
  margin: 0 !important;
}

#tutorial {
  .v-btn__content {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }
}

p {
  padding: 0 35px;
  font-size: 0.9em;
}
.v-card__actions {
  padding-top: 0px;
}

.v-btn {
  text-transform: none !important;
  letter-spacing: normal;
  // font-size: 17px !important;
  //height: 20px;
}

.fade-enter-active {
  transition: opacity 0.3s;
}
.fade-enter /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
