<!--
  This is the progress bar component. Handles application flow between steps.
-->

<template>
  <div class="progressWrapper">
    <v-stepper v-model="step" non-linear color="red">
      <v-stepper-header class="myStepper">
        <v-divider class="back"></v-divider>
        <div class="buttonContainer">
          <div class="buttonWrapper"></div>
        </div>
        <v-stepper-step
          step="1"
          :complete="step > 1"
          :color="step > 1 ? 'grey lighten-1' : '#838383'"
        >
          {{ text.DRAFT_INPUT }}
        </v-stepper-step>
        <!--#757575-->
        <v-stepper-step
          step="2"
          :complete="step > 2"
          :color="step === 2 ? '#838383' : 'grey lighten-1'"
        >
          {{ text.DRAFT_GENERATE }}
        </v-stepper-step>

        <div class="buttonContainer right">
          <div class="buttonWrapper">
            <DownloadMenu v-if="step === 2" @diagramDownload="$emit('diagramDownload')" />
          </div>
        </div>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import * as text from '@/data/constants/messages';
import DownloadMenu from './DownloadMenu';
import { DraftKeys as dk } from '@/data/constants/vuexKeys';

export default {
  name: 'ProgressBar',
  components: {
    DownloadMenu,
  },
  data() {
    return {
      text,
    };
  },

  methods: {
    ...mapMutations('draft', {
      increment: 'incrementStep',
      decrement: 'decrementStep',
      setGet: 'setGetDiagramsFromClaims',
    }),
  },

  computed: {
    ...mapState({
      step: (state) => state.draft[dk.STEP],
      furthestStep: (state) => state.draft[dk.FURTHEST_STEP],
    }),
    ...mapGetters('draft', ['validClaims', 'initialOutline']),
  },
};
</script>

<style lang="scss" scoped>
.v-step {
  border-radius: 4px !important;
  background-color: #fff !important;
  color: $app-text-color !important;
  text-align: left !important;
  padding: 0 !important;
  max-width: 420px !important;
  .v-step__arrow {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
  }
  .buttonContainer .v-btn__content {
    color: $app-text-color !important;
  }
  .v-btn__content {
    font-weight: normal !important;
    color: $app-text-color !important;
  }
}

.v-stepper {
  background-color: $light-gray !important;
  box-shadow: none !important;
  .v-stepper__header {
    box-shadow: none !important;
  }
  .v-stepper__content {
    box-shadow: none !important;
  }
  .v-stepper__step {
    padding: 0 16px;
    position: relative;
    z-index: 1;
    background-color: $light-gray;
  }
  .v-stepper__step--inactive {
    .v-stepper__step__step {
      background-color: red !important;
    }
  }
  .v-btn {
    //align-self: center;
    padding: 0 16px !important;
  }
  .v-divider {
    margin: 0 !important;
  }
  @media screen and (max-width: 420px) {
    height: 100px;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  width: 150px;
  max-width: 100%;
  &.right {
    justify-content: flex-end;
  }
}

#download {
  width: 162px;
  padding: 0 24px;
  @media screen and (max-width: 420px) {
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 2;
  }
}

#back {
  padding: 0 24px;
  @media screen and (max-width: 420px) {
    position: absolute;
    left: 0;
    top: 60px;
    z-index: 2;
  }
}

.progressWrapper {
  width: 100%;
}

.myStepper {
  position: relative;
}

.buttonWrapper {
  background-color: $light-gray;
  position: relative;
  z-index: 1;
}

.back {
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  z-index: 0;
  margin: auto;
}
</style>
