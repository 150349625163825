<!--
  Root parent of all other components. Global components and layouts can be set
  here.
-->
<template>
  <Scrollable class-name="app" ref="rootScroll">
    <v-app class="myFont">
      <v-fade-transition hide-on-leave>
        <router-view />
      </v-fade-transition>
    </v-app>
  </Scrollable>
</template>

<script>
import Scrollable from '@/components/global/Scrollable';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'App',
  components: {
    Scrollable,
  },
  data() {
    return {
      refresh: null,
    };
  },
  metaInfo: {
    title: 'Draft',
    titleTemplate: 'PatentPal %s',
  },

  async mounted() {
    if (localStorage.getItem('access_token')) {
      this.refreshAuth(true);
    }

    const content = !!window.sessionStorage.getItem('userContent');
    if (content) {
      this.getUserContent();
      this.setDraft(true);
    }

    const scrollInstance = this.$refs.rootScroll.$refs.osRef.osInstance();

    this.setDraftState({ key: 'rootScroll', val: scrollInstance });
  },

  beforeDestroy() {
    clearInterval(this.refresh);
  },

  computed: {
    ...mapGetters('draft', ['showGenerate']),
  },

  methods: {
    ...mapMutations('draft', ['getUserContent', 'setDraftState', 'setDraft']),
    refreshAuth(load = false) {
      if (window) {
        this.$auth.refresh(load);
      }
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import './scss/variables';

html {
  overflow-y: auto !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  //font-family: 'Open Sans', sans-serif !important;
  background: $light-gray;
}

body#pp-body > div.mxPopupMenu {
  border: none !important;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%) !important;
  border-radius: 4px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  tr {
    transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  td.mxPopupMenuItem {
    // display: none !important;
    height: 48px;
    font-size: 16px;
    font-family: 'Roboto';
    padding-left: 16px;
  }
}

.patentpal-tooltip {
  background-color: $dark-gray !important;
  opacity: 1 !important;
  transition: none !important;
}

.top-corners {
  border-radius: 0 0 4px 4px !important;
}

.remove-left-margin {
  left: 0 !important;
}

.remove-right-offset {
  right: 0 !important;
  left: initial !important;
}

.patentpal-menu {
  //box-shadow: none !important;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%) !important;
}

.v-btn.v-btn--icon {
  text-indent: 0;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold !important;
  letter-spacing: normal !important;
}

.black-text {
  color: $black;
}

.primary-text {
  color: $primary !important;
}

.dark-text {
  color: $app-text-color;
}

.soft-text {
  color: $soft-text-color !important;
}

.light-text {
  color: $light-text-color;
}

.panel-text {
  color: $panel-text-color;
}

.ql-container {
  font-family: 'Times New Roman', Times, serif, Arial, sans-serif !important;
}
.disabled-text {
  color: $disabled-text-color;
}

.app {
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

.v-menu__content.theme--light {
  background: white !important;
}

// Scroll bar styles
// ::-webkit-scrollbar-thumb {
//   height: 6px;
//   min-height: 55px !important;
//   border: 4px solid rgba(0, 0, 0, 0);
//   background-clip: padding-box;
//   background-color: rgba(0, 0, 0, 0.4);
//   -webkit-border-radius: 7px;
//   border-radius: 7px;
// }

// ::-webkit-scrollbar-button {
//   display: none;
//   width: 0;
//   height: 0;
// }

// ::-webkit-scrollbar-corner {
//   background-color: transparent;
// }

.my-transition {
  &-enter-active,
  &-enter,
  &-enter-to {
    opacity: 1 !important;
  }

  &-enter-active {
    transition: opacity 0.3s !important;
  }

  &-leave,
  &-leave-to {
    transition: opacity 0.5s !important;
  }

  //&-enter-active,
  &-enter,
  &-leave-to {
    opacity: 0 !important;
  }
}
</style>
