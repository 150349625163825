export const PHRASES = [
  'Analyzing claims...',
  'Extracting concepts...',
  'Connecting concepts...',
  'Building graphs...',
  'Rendering diagrams...',
  'Generating description...',
];

// How long each phrase will be shown (in milliseconds)
export const PHRASE_DURATION = 3000;
