<template>
  <v-container tag="section">
    <v-form ref="form" class="px-6">
      <v-text-field v-model="email" label="Email" solo :rules="[rules.required]" dense light />
      <v-text-field
        v-model="password"
        label="Password"
        solo
        dense
        light
        type="password"
        :rules="[rules.required]"
      />
      <router-link
        :to="{ path: 'login', query: { reset: true } }"
        type="button"
        class="align-self-end mt-n6 mb-4 text-decoration-underline primary--text passwordButton caption"
      >
        {{ FORM_FORGOT }}
      </router-link>

      <v-btn
        width="100%"
        height="50"
        color="primary"
        class="my-3"
        :disabled="disabled"
        :loading="loading"
        @click.prevent="login"
      >
        {{ GEN_LOG_IN }}
      </v-btn>
      <v-btn
        width="100%"
        height="40"
        light
        color="grey lighten-1"
        class="my-3"
        @click="$auth.universalLogin({ connection: 'google-oauth2' })"
      >
        <div class="d-flex align-center justify-center">
          <span class="font-weight-regular social-text mr-3">Log in with Google</span>
          <v-img src="@/data/static/GoogleLogo.png" max-width="20" />
        </div>
      </v-btn>
      <v-btn
        width="100%"
        height="40"
        light
        color="grey lighten-1"
        class="my-3"
        @click="$auth.universalLogin({ connection: 'linkedin' })"
      >
        <div class="d-flex align-center justify-center">
          <span class="font-weight-regular social-text mr-3">Log in with LinkedIn</span>
          <v-img src="@/data/static/LinkedInLogo.png" max-width="20" />
        </div>
      </v-btn>
      <v-snackbar v-model="error.value" top color="error" text absolute light>
        {{ error.message }}
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="error.value = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-form>
    <p class="caption mt-4 white--text">
      {{ FORM_RETURN_USER }}{{ ' ' }}<a @click="goToSignup">{{ GEN_SIGN_UP }}</a>
    </p>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import {
  FORM_RETURN_USER,
  FORM_FORGOT,
  FORM_GOOGLE,
  FORM_LINKEDIN,
  GEN_LOG_IN,
  GEN_SIGN_UP,
} from '@/data/constants/messages';

export default {
  name: 'LoginWidget',
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      error: {
        value: false,
        message: '',
      },
      rules: {
        required: (val) => !!val || 'Required',
      },
      FORM_LINKEDIN,
      FORM_GOOGLE,
      FORM_FORGOT,
      FORM_RETURN_USER,
      GEN_LOG_IN,
      GEN_SIGN_UP,
    };
  },

  computed: {
    /**
     * @returns {Boolean}
     */
    disabled() {
      return this.password.length < 1 || this.email.length < 1;
    },
  },

  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          await this.$auth.login(this.email, this.password);
          this.loading = false;
        } catch (err) {
          this.error.value = true;
          this.error.message = err.description;
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  max-width: 100%;
  width: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
}

.v-text-field {
  width: 100%;
}

.v-btn {
  font-size: 18px !important;
  font-weight: bold !important;
}

.social-text {
  font-size: 15px !important;
}

.passwordButton {
  position: relative;
  z-index: 2;
}
</style>
